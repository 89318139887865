<template>
  <div>
    <!-- Header -->
    <CustomHeader></CustomHeader>

    <!-- Products & Services -->
    <div class="products-section-user" id="products">

      <!-- I Produce Food -->
      <div class="products-section-column">
        <img src="@/assets/product_food.png">
        <span>I produce food</span>
        <v-btn href="#productgraphic" @click="showImage =! showImage">Learn More</v-btn>
      </div>

      <!-- I Provide Resources to Food Producers -->
      <div class="products-section-column">
        <img src="@/assets/product_building.png">
        <span>I provide resources to food producers</span>
        <v-btn href="#form">Contact Us</v-btn>
      </div>
    </div>

    <!-- Products and Services -->
    <v-sheet v-if="showImage" class="products-section-info">
      <a id="productgraphic" style="position:absolute;height:100px;margin-top:-100px;"></a>
      <div class="products-info-container">

        <!-- CRM -->
        <div class="products-info-column">
          <img src="@/assets/product_crm.png" style="max-width:150px;height:auto;">
          <span>CRM for Customer Relationships</span>
        </div>

        <!-- Order Aggregation Engine -->
        <div class="products-info-column">
          <img src="@/assets/product_orderengine.png" style="max-width:150px;height:auto;">
          <span>Order Aggregation Engine</span>
        </div>

        <!-- Accounting Integration -->
        <div class="products-info-column">
          <img src="@/assets/product_accounting.png" style="max-width:150px;height:auto;">
          <span>Accounting Integration</span>
        </div>

        <!-- Reporting Tool(s) -->
        <div class="products-info-column">
          <img src="@/assets/product_reporting.png" style="max-width:150px;height:auto;">
          <span>Reporting Tool(s)</span>
        </div>

        <!-- Supplier Management -->
        <div class="products-info-column">
          <img src="@/assets/product_supplier.png" style="max-width:150px;height:auto;">
          <span>Supplier Management</span>
        </div>

        <!-- Product Planning Tools -->
        <div class="products-info-column">
          <img src="@/assets/product_planning.png" style="max-width:150px;height:auto;">
          <span>Product Planning Tools</span>
        </div>

        <!-- Warehouse Management -->
        <div class="products-info-column">
          <img src="@/assets/product_warehouse.png" style="max-width:150px;height:auto;">
          <span>Warehouse Management</span>
        </div>

        <!-- B2B Rideshare for Food -->
        <div class="products-info-column">
          <img src="@/assets/product_truck.webp" style="max-width:150px;height:auto;">
          <span>B2B Rideshare for Food</span>
        </div>
      </div>
    </v-sheet>

    <!-- Our Friends -->

    <v-sheet class="section-header">
      <span class="font-weight-bold about-title">Our Friends</span>
    </v-sheet>

    <!-- Sip & Sonder -->
    <v-sheet class="sipandsonder-container" style="height:auto; overflow:hidden;">
      <div class="ourfriends-column">
        <a href="https://www.sipandsonder.com/shop-all" target="blank">
          <img src="@/assets/sipandsonder_product.webp">
        </a>
      </div>
      <div class="ourfriends-column">
        <a href="https://www.sipandsonder.com/about" target="blank">
          <img src="@/assets/sipandsonder_team.webp">
        </a>
      </div>
      <div class="sipandsonder">
        <span>Sip & Sonder</span>
        <span>Coffee Roastery & Coffee House</span>
        <a href="https://www.sipandsonder.com/" target="blank">Learn More</a>
      </div>
    </v-sheet>

    <!-- Ghost Town Oats -->
    <v-sheet class="ghosttownoats-container" style="height:auto; overflow:hidden;">
      <div class="ghosttownoats">
        <span>Ghost Town Oats</span>
        <span>Premium Oat Milk Provider</span>
        <a href="https://www.ghosttown.world/" target="blank">Learn More</a>
      </div>
      <div class="ourfriends-row-container">
        <div class="ourfriends-row">
          <a href="https://www.ghosttown.world/pages/about" target="blank">
            <img src="@/assets/ghosttown_team.webp">
          </a>
        </div>
        <div class="ourfriends-row">
          <a href="https://www.ghosttown.world/collections/shop" target="blank">
            <img src="@/assets/ghosttown_product.webp">
          </a>
        </div>
      </div>
    </v-sheet>

    <!-- Form Section -->
    <v-sheet id="form" class="form-section">
      <img style="display:block; margin:auto; max-width: 250px;" src="@/assets/pollinatelogo.webp">
      <span class="form-title font-weight-bold py-16">
        Send Pollinate an Email
      </span>
      <span style="margin-bottom: 60px;">
        Please use the form below for general questions about Pollinate and our products.
        If you are looking to schedule a DEMO, please
        <a href="https://meetings.hubspot.com/todd-edman" target="blank">click here</a> instead.
      </span>
      <CustomForm></CustomForm>
    </v-sheet>

    <!-- About Us Section-->
    <v-sheet class="about-section">
      <span class="about-title font-weight-bold white--text py-16 drop-shadow">About Us</span>
      <span style="margin-bottom:60px;">
        At Pollinate, we want to make food communities as happy as they make us.
        Because we love enjoying good food and drinks made by great people.
        Plus, you won't have to clone yourself. So that's a bonus.
      </span>

      <!-- About Images-->
      <div class="about-image-container">
        <div class="about-image about-image-left">
          <img src="@/assets/about_happy.webp">
        </div>
        <div class="about-image" style="z-index:2;">
          <img src="@/assets/about_foodtruck.webp">
        </div>
        <div class="about-image about-image-right">
          <img src="@/assets/about_coffee.webp">
        </div>
      </div>
    </v-sheet>

  </div>
</template>

<script type="text/javascript">

import CustomForm from '@/components/CustomForm.vue';
import CustomHeader from '@/components/CustomHeader.vue';

export default {
  name: 'HomeView',

  created() {
    document.title = 'Home | Pollinate';
  },

  components: {
    CustomForm,
    CustomHeader,
  },

  data() {
    return {
      showImage: false,
    }
  }
}
</script>

<style scoped>
.products-section-user {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 100px;
  align-items: center;
  text-align: center;
  flex-direction: row;
  width: 100%;
  padding: 60px;
  background-color: #f3f3f3;
}

.products-section-user .v-icon {
  font-size: 150px;
  color: #508f34;
}

.products-section-user .v-btn {
  background-color: #B86225;
  color: #ffffff;
}

.products-section-column {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  height: 350px;
  gap: 30px;
  justify-content: space-between;
  align-items: center;
}

.products-section-column img {
  max-width: 150px;
}

.products-section-column span {
  font-size: 32px;
  font-weight: bold;
}

.products-info-container {
  background-color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 60px 30px;
}

.products-info-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 25%;
  flex-grow: 1;
  max-width: calc(25% - 60px);
  margin: 30px;
  gap: 30px;
  text-align: center;
}

.products-info-column img {
  max-width: 150px;
}

.products-info-column span {
  font-size: 16px;
  font-weight: bold;
  color: #333333;
}

.section-header {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #333333;
  height: 125px;
  background-color: #f3f3f3;
}

.ourfriends-column {
  width: 33%;
  height: 750px;
}

.ourfriends-column img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.ourfriends-row-container {
  width: 50%;
}

.ourfriends-row {
  width: 100%;
  height: auto;
  max-height: 374.5px;
  background-color: #faaba5;
}

.ourfriends-row img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.sipandsonder-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.sipandsonder {
  background: radial-gradient(circle at top right, transparent 10%, #2D631A 10%, #2D631A 20%, transparent 21%), radial-gradient(circle at left bottom, transparent 10%, #2D631A 10%, #2D631A 20%, transparent 21%), radial-gradient(circle at top left, transparent 10%, #2D631A 10%, #2D631A 20%, transparent 21%), radial-gradient(circle at right bottom, transparent 10%, #2D631A 10%, #2D631A 20%, transparent 21%), radial-gradient(circle at center, #2D631A 30%, transparent 31%);
  background-size: 3em 3em;
  background-color: #275518;
  opacity: 1;
  flex-grow: 1;
  height: 750px;
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  padding: 16px;
}

.sipandsonder span {
  color: #ffffff;
  font-size: 32px;
  text-align: center;
  text-shadow: 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.sipandsonder a {
  color: #ffffff;
  font-size: 24px;
  text-decoration: underline;
  font-style: italic;
}

.ghosttownoats-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.ghosttownoats {
  background: radial-gradient(circle at top, transparent 14%, #D95765 15%, #D95765 19%, transparent 20%), radial-gradient(circle at bottom, transparent 14%, #D95765 15%, #D95765 19%, transparent 20%), radial-gradient(circle at right, transparent 14%, #D95765 15%, #D95765 19%, transparent 20%), radial-gradient(circle at left, transparent 14%, #D95765 15%, #D95765 19%, transparent 20%), radial-gradient(circle at center, #D95765 25%, transparent 26%), radial-gradient(circle at bottom left, #D95765 12%, transparent 13%), radial-gradient(circle at bottom right, #D95765 12%, transparent 13%), radial-gradient(circle at top left, #D95765 12%, transparent 13%), radial-gradient(circle at top right, #D95765 12%, transparent 13%);
  background-size: 3em 3em;
  background-color: #DA4554;
  opacity: 1;
  flex-grow: 1;
  height: auto;
  width: 33%;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  padding: 16px;
}

.ghosttownoats span {
  color: #ffffff;
  font-size: 32px;
  text-align: center;
  text-shadow: 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.ghosttownoats a {
  color: #ffffff;
  font-size: 24px;
  text-decoration: underline;
  font-style: italic;
}

.form-section {
  height: auto;
  width: 100%;
  padding: 60px 6%;
  background-color: #f3f3f3;
}

.form-title {
  font-size: 42px;
  text-align: center;
  display: block;
  margin: auto;
}

.form-section span {
  font-size: 32px;
  text-align: center;
  display: block;
}

.about-section {
  height: auto;
  width: 100%;
  padding: 60px 6%;
  --s: 160px;
  /* control the size */
  --c1: #B86226;
  --c2: #C46A2B;
  --_g: var(--s) var(--s) radial-gradient(var(--c1) 17%, var(--c2) 18% 35%, #0000 36.5%);
  background:
    calc(var(--s)/-4) calc(var(--s)/-4)/var(--_g),
    calc(var(--s)/ 4) calc(var(--s)/ 4)/var(--_g),
    radial-gradient(var(--c2) 34%, var(--c1) 36% 68%, #0000 70%) 0 0/calc(var(--s)/2) calc(var(--s)/2),
    repeating-linear-gradient(45deg, var(--c1) -12.5% 12.5%, var(--c2) 0 37.5%) 0 0/var(--s) var(--s);
}

.about-title {
  font-size: 42px;
  text-align: center;
  display: block;
  margin: auto;
}

.about-section span {
  font-size: 32px;
  text-align: center;
  display: block;
  color: #ffffff;
  text-shadow: 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.about-image-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0px;
}

.about-image {
  height: auto;
  width: 250px;
  aspect-ratio: 1;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0px 4px 0px rgba(0, 0, 0, 0.25);
  position: relative;
}

.about-image-left {
  z-index: 1;
  right: -15px;
  max-width: 200px;
  max-height: 200px;
}

.about-image-right {
  z-index: 1;
  left: -15px;
  max-width: 200px;
  max-height: 200px;
}

.about-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/* Large Screens */
@media screen and (min-width: 1441px) {
  .products-section-user {
    gap: 250px;
  }

  .ourfriends-row {
    max-height: 600px;
  }

  .ghosttownoats span, .sipandsonder span {
    font-size: 42px;
  }

  .ghosttownoats a, .sipandsonder a {
    font-size: 32px;
  }
}

/* Tablet */
@media screen and (max-width: 768px) {
  .products-section-user {
    padding: 60px 30px;
  }

  .products-info-column {
    flex-basis: 50%;
    max-width: calc(50% - 60px);
  }

  .sipandsonder-container {
    flex-direction: column-reverse;
  }

  .sipandsonder {
    width: 100%;
  }

  .ghosttownoats-container {
    flex-direction: column;
  }

  .ghosttownoats {
    width: 100%;
    height: 750px;
  }

  .ourfriends-column {
    width: 100%;
  }

  .ourfriends-row-container {
    width: 100%;
  }

  .ourfriends-row {
    width: 100%;
  }
}

/* Mobile */
@media screen and (max-width: 450px) {
  .products-info-column {
    flex-basis: 100%;
    max-width: calc(100% - 60px);
  }
  
  .form-section span {
    font-size: 24px;
  }

  .form-title {
    font-size: 24px;
    text-align: center;
    display: block;
    margin: auto;
}

  .about-image-container {
    flex-wrap: wrap;
    gap: 30px;
  }

  .about-title {
    font-size: 24px;
    text-align: center;
    display: block;
    margin: auto;
  }

  .about-section span {
    font-size: 24px;
  }

  .about-image {
    max-width: 200px;
  }

  .about-image-left, .about-image-right {
    right: 0px;
    left: 0px;
  }
}
</style>
