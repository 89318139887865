import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    loadedGoogleAPI: false,
  },
  mutations: {
    setLoaded (state) {
      state.loadedGoogleAPI = true
    }
  }
})
console.log('instantiated')

var script = document.createElement( "script" )
script.type = "text/javascript";
script.src = "https://maps.googleapis.com/maps/api/js?key="+process.env.VUE_APP_GOOGLE_API_KEY+"&libraries=places";
script.onload = function(){
  console.log('api loaded')
  store.commit("setLoaded")
}
script.onerror = function(){
  store.commit("setLoaded")
}
document.getElementsByTagName( "head" )[0].appendChild( script );
console.log(script.src);

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')
