import { render, staticRenderFns } from "./CustomHeader.vue?vue&type=template&id=f11e5974&scoped=true"
var script = {}
import style0 from "./CustomHeader.vue?vue&type=style&index=0&id=f11e5974&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f11e5974",
  null
  
)

export default component.exports