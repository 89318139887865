<template>
  <div id="hubspotForm" v-once></div>
</template>

<script type="text/javascript">
  export default {
    mounted() {
    const script = document.createElement("script");
    script.src="https://js.hsforms.net/forms/v2.js";
    document.body.appendChild(script);
    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          portalId: "44782948",
          formId: "eadd7ba9-daa3-43c9-8849-32c65ed19606",
          target: "#hubspotForm"
        })
      }
    })
  }
}
</script>