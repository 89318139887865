<template>
  <v-app>
    <CustomTopBar></CustomTopBar>
    <v-main>
      <router-view/>
    </v-main>
    <CustomFooter></CustomFooter>
  </v-app>
</template>

<script>
import CustomTopBar from '@/components/CustomTopBar.vue';
import CustomFooter from '@/components/CustomFooter.vue';

export default {
  name: 'PollinateMarketingSite',

  components: {
    CustomTopBar,
    CustomFooter,
  },

  data: () => ({
    //
  }),
};
</script>

<style>
  .v-application {
    font-family: 'PT Sans', sans-serif;
  }

  .v-text-field--outlined, .v-text-field--solo, .v-btn {
    border-radius: 25px !important;
  }

  .drop-shadow {
    text-shadow: 0px 4px 0px rgba(0, 0, 0, 0.25);
  }
</style>
