<template>
  <div>
    <v-app-bar app color="#fefefe" dark height="125" elevation="4" class="topbar-container">
      <div class="d-flex align-center">
        <a href="/">
          <img class="website-logo" src="@/assets/pollinatelogo.webp">
        </a>
      </div>

      <v-spacer></v-spacer>

      <!-- Nav Links -->
      <v-btn class="nav-link" text color="#333333" :ripple="false" href="/">Home</v-btn>
      <v-btn class="nav-link" text color="#333333" :ripple="false" href="/#products">Products</v-btn>
      <v-btn class="nav-link" text color="#333333" :ripple="false" href="/community">Community</v-btn>
      <v-btn class="nav-link" text color="#333333" :ripple="false" href="/resources">Resources</v-btn>
      <v-btn class="nav-link" text color="#333333" :ripple="false" href="/#form">Contact</v-btn>

      <!-- Nav Menu -->
      <v-btn icon @click="showMobileMenu =! showMobileMenu">
        <v-icon class="mobile-menu-button" style="color:#333333;font-size:40px;" @click="toggleIcon">{{ icon }}</v-icon>
      </v-btn>

      <v-spacer class="social-spacer"></v-spacer>

      <!-- Social Icons-->
      <div class="social-icon-container">
        <v-btn class="social-icon" icon small color="#498B2A" href="https://www.facebook.com/profile.php?id=61559120441779" target="_blank">
          <v-icon>mdi-facebook</v-icon>
        </v-btn>

        <v-btn class="social-icon" icon small color="#498B2A" href="https://www.instagram.com/getpollinate" target="_blank">
          <v-icon>mdi-instagram</v-icon>
        </v-btn>

        <v-btn class="social-icon" icon small color="#498B2A" href="https://www.linkedin.com/company/getpollinate/" target="_blank">
          <v-icon>mdi-linkedin</v-icon>
        </v-btn>

        <v-btn class="social-icon" icon small color="#498B2A" href="https://www.youtube.com/channel/UCUHm3aXBbIDD_FwnqD4UpHA" target="_blank">
          <v-icon>mdi-youtube</v-icon>
        </v-btn>

        <v-btn class="social-icon" icon small color="#498B2A" href="https://www.tiktok.com/@getpollinate" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" 
            fill="#498B2A" 
            x="0px" 
            y="0px" 
            width="24" 
            height="24" 
            viewBox="0 0 24 24"
            >
              <path d="M 6 3 C 4.3550302 3 3 4.3550302 3 6 L 3 18 C 3 19.64497 4.3550302 21 6 21 L 18 21 C 19.64497 21 21 19.64497 21 18 L 21 6 C 21 4.3550302 19.64497 3 18 3 L 6 3 z M 12 7 L 14 7 C 14 8.005 15.471 9 16 9 L 16 11 C 15.395 11 14.668 10.734156 14 10.285156 L 14 14 C 14 15.654 12.654 17 11 17 C 9.346 17 8 15.654 8 14 C 8 12.346 9.346 11 11 11 L 11 13 C 10.448 13 10 13.449 10 14 C 10 14.551 10.448 15 11 15 C 11.552 15 12 14.551 12 14 L 12 7 z"></path>
          </svg>
        </v-btn>        
      </div>
      
    <!-- Mobile Menu -->
    </v-app-bar>
    <v-sheet v-if="showMobileMenu" class="mobile-menu" elevation="4">
      <v-btn class="mobile-nav-link" @click="closeMenu" text color="#ffffff" :ripple="false" href="/#products">Products</v-btn>
      <v-btn class="mobile-nav-link" @click="closeMenu" text color="#ffffff" :ripple="false" href="/community">Community</v-btn>
      <v-btn class="mobile-nav-link" @click="closeMenu" text color="#ffffff" :ripple="false" href="/resources">Resources</v-btn>
      <v-btn class="mobile-nav-link" @click="closeMenu" text color="#ffffff" :ripple="false" href="/#form">Contact</v-btn>

      <!-- Mobile Social Icons-->
      <div class="social-icon-container-mobile">
        <v-btn class="social-icon" icon small color="#ffffff" href="https://www.facebook.com/profile.php?id=61559120441779" target="_blank">
          <v-icon>mdi-facebook</v-icon>
        </v-btn>

        <v-btn class="social-icon" icon small color="#ffffff" href="https://www.instagram.com/getpollinate" target="_blank">
          <v-icon>mdi-instagram</v-icon>
        </v-btn>

        <v-btn class="social-icon" icon small color="#ffffff" href="https://www.linkedin.com/company/getpollinate/" target="_blank">
          <v-icon>mdi-linkedin</v-icon>
        </v-btn>

        <v-btn class="social-icon" icon small color="#ffffff" href="https://www.youtube.com/channel/UCUHm3aXBbIDD_FwnqD4UpHA" target="_blank">
          <v-icon>mdi-youtube</v-icon>
        </v-btn>

        <v-btn class="social-icon" icon small color="#ffffff" href="https://www.tiktok.com/@getpollinate" target="_blank">
          <svg xmlns="http://www.w3.org/2000/svg" 
            fill="#ffffff" 
            x="0px" 
            y="0px" 
            width="24" 
            height="24" 
            viewBox="0 0 24 24"
            >
              <path d="M 6 3 C 4.3550302 3 3 4.3550302 3 6 L 3 18 C 3 19.64497 4.3550302 21 6 21 L 18 21 C 19.64497 21 21 19.64497 21 18 L 21 6 C 21 4.3550302 19.64497 3 18 3 L 6 3 z M 12 7 L 14 7 C 14 8.005 15.471 9 16 9 L 16 11 C 15.395 11 14.668 10.734156 14 10.285156 L 14 14 C 14 15.654 12.654 17 11 17 C 9.346 17 8 15.654 8 14 C 8 12.346 9.346 11 11 11 L 11 13 C 10.448 13 10 13.449 10 14 C 10 14.551 10.448 15 11 15 C 11.552 15 12 14.551 12 14 L 12 7 z"></path>
          </svg>
        </v-btn>        
      </div>

    </v-sheet>
  </div>
</template>

<script type="text/javascript">
export default {
  name: 'CustomTopBar',

  data() {
    return {
      showMobileMenu: false,
      icon: 'mdi-menu'
    }
  },

  methods: {
    toggleIcon() {
      this.icon = (this.icon === 'mdi-menu') ? 'mdi-window-close' : 'mdi-menu';
    },

    closeMenu() {
      this.showMobileMenu = false;
      this.toggleIcon();
    }
  }
}
</script>

<style scoped>
.topbar-container {
  padding: 0 30px;
}
.website-logo {
  width: auto;
  height: 50px;
}

.mobile-menu-button {
  display: none;
}

.mobile-menu {
  display: none;
}

.social-icon-container-mobile {
  display: flex;
  gap: 15px;
}

/* Mobile Site */
@media screen and (max-width: 425px) {

  .website-logo {
    width: auto;
    height: 50px;
  }

  .nav-link {
    display: none;
  }
  
  .mobile-menu-button {
    display: flex;
  }

  .mobile-menu {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #51913F;
    width: 100vw;
    height: calc(100vh + 125px);
    padding: 15px 0px;
    gap: 60px;
    position: fixed;
    z-index: 3;
  }
}

/* Tablet Site */
@media screen and (max-width: 768px) and (min-width: 426px) {
  .topbar-container {
    padding: 0 30px;
  } 

  .nav-link {
    display: none;
  }

  .mobile-menu-button {
    display: flex;
  }

  .mobile-menu {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #51913F;
    width: 100vw;
    height: calc(100vh + 125px);
    padding: 15px 0px;
    gap: 60px;
    position: fixed;
    z-index: 3;
  }

  .social-icon-container-mobile {
    display: flex;
    gap: 30px;
  }
}

/* Small Labtops */
@media screen and (max-width: 1024px) {
    .social-icon-container, .social-spacer {
      visibility: hidden;
      max-width: 0;
    }
}

</style>